import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Clients = ({ title }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileQuery = window.matchMedia("(max-width: 1024px)");
      setIsMobile(isMobileQuery.matches);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const dynamicImages = [
    {
      title: "visa",
      images: ["visa.webp"],
      width: { lg: 67.5, md: 59.35 },
    },
    {
      title: "lode-stone",
      images: ["lode-stone.webp"],
      width: { lg: 91, md: 72.05 },
    },
    {
      title: "adani",
      images: ["adani.webp"],
      width: { lg: 69, md: 63.42 },
    },
    {
      title: "tvs",
      images: ["tvs.webp"],
      width: { lg: 102, md: 82.19 },
    },
    {
      title: "iima",
      images: ["iima.webp"],
      width: { lg: 53, md: 34.95 },
    },
    {
      title: "intellect",
      images: ["intellect.webp"],
      width: { lg: 116.89, md: 75.65 },
    },
    {
      title: "aditya-birla",
      images: ["aditya-birla.webp"],
      width: { lg: 122, md: 88.68 },
    },
    {
      title: "kantime",
      images: ["kantime.webp"],
      width: { lg: 73, md: 66.23 },
    },
  ];

  const imageData = useStaticQuery(graphql`
    query {
      dynamicImages: allFile(
        filter: {
          relativePath: {
            regex: "/^(visa|talk-back|survey-connect|vet-it|lode-stone|iima|tvs|adani|intellect|aditya-birla|kantime|attentive|ship-delight|kesari|shoppers|nirvana)/"
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const imagesByPath = {};
  imageData.dynamicImages.edges.forEach(({ node }) => {
    const imagePath = node.relativePath;
    const imageData = getImage(node.childImageSharp);
    imagesByPath[imagePath] = imageData;
  });
  const createMarkup = (content) => {
    return { __html: content };
};
  return (
    <section className="lg:py-[200px] md:py-[120px] py-[80px] bg-[#fff]">
      <div className="container">
        <h2
          className="font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[876px] lg:mb-[60px] md:mb-[40px] mb-[20px]"
          dangerouslySetInnerHTML={createMarkup(title)}
        ></h2>
        <div className="py-[30px] overflow-x-auto md:overflow-visible  md:grid-cols-4 grid grid-flow-col grid-rows-2 gap-0 clients-grid w-full relative z-[1]">
          {dynamicImages.map(({ title, images, width }, index) => {
            return (
              <div
                className="w-[160px] md:w-full h-[160px] flex items-center justify-center relative"
                key={index}
              >
                <GatsbyImage
                  style={{ width: isMobile ? width.md : width.lg }}
                  image={imagesByPath[images[0]]}
                  alt={title ? title : 'clients'}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Clients;
