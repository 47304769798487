import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = ({}) => {
  // const { birthTime, modifiedTime } = data.file

  const banner = {
    title: `AngularJS Development Company`,
    content:
      "Your Trusted AngularJS Development Company transforms bespoke solutions into pixel-perfect Angular UI for improved web performance.",
  };
  const commonContent6 = {
    title: "Bespoke AngularJS Development Services",
    para: "With our Angular JS Development Services, we create comprehensive solutions and rigorously test them to ensure they are highly functional and scalable for long-term business growth.",
    img: ["what-service-image-angular.webp"],
    imageTitle: "Angular Development Agency",
    imageAlt: "angular js web development services",
    content: [
      {
        title: "AngularJS Web Development ",
        text: "With our Angularjs web development services, we create robust web applications that are interactive and intuitive.",
      },
      {
        title: "Mobile App Development ",
        text: "We maintain updated and optimized applications for maximum user engagement on all devices.",
      },
      {
        title: "AngularJS SPA Development",
        text: "We work on increasing the page's site speed to boost overall interactivity and web presence.",
      },
      {
        title: "AngularJS Consulting",
        text: "We provide insight and strategic solutions for Angular projects to increase website performance.",
      },
      {
        title: "Custom Development",
        text: "We offer tailored Angular JS development services depending on your company's requirements and project specifications.",
      },
      {
        title: "AngularJS Migration Services",
        text: "We also provide migration services for a smoother website changeover and improved security.",
      },
    ],
  };
  const commonContent = {
    title: "Industries we serve as <span class='h1-span'>Angular Development Company</span> ",
    para: "As the best AngularJS development company, we broaden our expertise and provide tailored solutions to organizations in a variety of industries to optimize their websites and improve their performance.",
    img: ["who-needs-angular.webp"],
    imageTitle: "Angular Development Services",
    imageAlt: "angular development company",
    content: [
      {
        title: "E-commerce Companies",
        text: "Companies that wish to combine different platforms and provide a smooth customer experience, increased engagement, etc.",
      },
      {
        title: "Financial Institutions",
        text: "Companies aim to create safe and secure websites that safeguard their consumers' interests and privacy while gaining customer loyalty.",
      },
      {
        title: "Wellness Companies",
        text: "Companies looking to construct dynamic websites that provide tailored solutions for better customer service.",
      },
      {
        title: "SaaS Companies",
        text: "Companies that wish to boost the adoption and engagement of their website by providing new solutions.",
      },
    ],
  };

  const commonContent2 = {
    title: "Harness the Power of our AngularJS Development Agency",
    para: "Our AngularJS Company helps businesses stay ahead of the digital curve and achieve long-term growth by providing scalable solutions that boost productivity and website performance.",
    img: ["what-are-benefits-angular.webp"],
    imageTitle: "Benefits of Angular Development",
    imageAlt: "angular development services",
    content: [
      {
        title: "Enhanced Performance ",
        text: "Our AngularJS Web Development Services enhances website performance through efficient rendering, optimized code, and modular architecture.",
      },
      {
        title: "Scalable Solution",
        text: "Our AngularJS Developers focus on a component-based approach, allowing scalable solutions and easy website modifications.",
      },
      {
        title: "Cross-platform Development",
        text: "Our Angular.js Development Company allows you to seamlessly integrate your website with different platforms, resulting in greater accessibility and usefulness.",
      },
      {
        title: "Rich User Interfaces",
        text: "Our Angular Development Services produces functional and visually appealing websites using a rich library and data binding features.",
      },
    ],
  };
  const commonContent3 = {
    title: "Dream Big with the Top Angular.js Development Company",
    linkText: ``,
    para: "Octet is a top Angular JS Development Agency that offers industry-specific Angular knowledge while understanding your business difficulties in developing high-quality websites.",
    img: ["why-opt-for-angular.webp"],
    imageTitle: "Our Angular Development Expertise",
    imageAlt: "angularjs development company",
    content: [
      {
        title: "Quality Assurance",
        text: "We conduct rigorous quality checks based on industrial standards to test the usability, reliability and performance of your website.",
      },
      {
        title: "Cutting-edge technologies",
        text: "Our AngularJS Developers stay current on the latest technology to design high-performing websites and maximize their potential for delivering customized solutions.",
      },
      {
        title: "Multi-Platform Integration",
        text: "We employ Angular's diverse capabilities to combine websites with various applications for better accessibility.",
      },
      {
        title: "Continuous Support",
        text: "Octet delivers ongoing support after the development to ensure it remains bug-free and current with market trends.",
      },
    ],
  };
  const commonContent4 = {
    title: "Process Followed by our Angular Development Agency",
    para: "At Octet, we provide comprehensive and tailored AngularJS Development Services. We take a transparent approach to meeting your business objectives and delivering remarkable outcomes.",
    img: ["how-we-conduct-angular.webp"],
    imageTitle: "Process of Angular Development",
    imageAlt: "top angular development company",
    content: [
      {
        title: "1. Define UX & Flows",
        text: "We initially identify the UX characteristics and user processes to determine the design structure.",
      },
      {
        title: "3. Build HTML Templates",
        text: "We create designs while considering brand requirements, typographic design principles, color theory, and UX rules.",
      },
      {
        title: "2. Create UI Designs",
        text: "We then transform the user interface design into HTML templates that are converted into Angular pages.",
      },
      {
        title: "4. Convert to Angular Pages",
        text: "Finally, we convert the HTML code to an Angular framework for easy understanding and faster deployment.",
      },
    ],
  };
  const commonContent5 = {
    title: "Invest in the Best AngularJS Development Company",
    para: "Maximise ROI by investing in our Angular App Development Company, which enables your company to create dynamic websites with a central ecosystem, utilizing the most recent usability techniques, code reusability, etc.",
    content: [
      {
        title: "20% Saved Development Time",
        text: "AngularJs' enhanced features reduce the time required for development.",
      },
      {
        title: "15% Higher Productivity",
        text: "AngularJs' capabilities, such as a structured framework and code reuse, boost efficiency.",
      },
      {
        title: "15% Code Maintainability",
        text: "Angular's component-based architecture ensures code maintenance and reusability.",
      },
      {
        title: "30% Better Efficiency",
        text: "AngularJs boosts productivity by allowing hybrid web development to create programs across several devices and platforms.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them*",
  };
  const cta = {
    title: "Shape Ideas into Agile Angular Solutions",
  };
  const cta2 = {
    title: "Revolutionize Web App with Angular Development",
  };
  const cta3 = {
    title: "Experience the Angular Advantage",
  };
  const cta4 = {
    title: "Start your Angular Project Today",
  };
  const cta5 = {
    title: "Increase Business ROI Utilize Our AngularJs Development!",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "KYS",
      desc: "Supplier GST Performance Platform",
      Industry: "SaaS",
      link: "/project/ui-ux-design-kys/",
      firstAlt: "angular development agency",
      firstTitle: "Dashboard",
      secondAlt: "angular app development company",
      secondTitle: "Suppliers Solution",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Angular Development",
          link: "/angularjs-development/",
        },
      ],
      images: ["kys.webp", "kys-2.webp"],
    },
    // {
    //   title: "Vet IT",
    //   desc: "A Cloud-Based practice management software.",
    //   Industry: "HealthTech",
    //   link: '/project/vet-clinic',
    //   WhatWeDid: [
    //     {
    //       title: 'Design Audit',
    //       link: '/design-audit/'
    //     },
    //     {
    //       title: 'UI UX Designing',
    //       link: '/ui-ux-designing/'
    //     },
    //     {
    //       title: 'UI Development',
    //       link: '/ui-development/'
    //     }
    //   ],
    //   images: ["vet-it.webp", "vet-it-2.webp"],
    // },
  ];

  const Questions = [
    "Can your AngularJS Company integrate Angular with other design workflows?",
    "Does your AngularJS Development Agency offer dedicated Angular Developers?",
    "Do you offer templates made in Angular?",
    "What are your services under Angular.js Development Company?",
    "How does your Angular Development Company enhance the user experience?",
    "What is the duration and pricing of your Angular Development Services?",
  ];
  const clientsTitle =
    "Trusted By Clients Worldwide";
  const faqDes = `We understand you may have further queries about our Angular JS Development Agency. As a result, we have answered some of our clients' most frequently asked questions. However, if you still have any questions, please <a href="/contact-us/">contact us</a>.`;
  const faqData = [
    {
      para: [
        `Yes! As an Angular Application Development Agency, we have the expertise and experience to integrate Angular with other design workflows and create an interactive, highly functional, visually attractive, and intuitive website.`,
      ],
    },
    {
      para: [
        `Yes, we do offer dedicated Angular Developers who are experts in creating dynamic web solutions for different industries.`,
        `Our dedicated AngularJS Developers fulfill your project requirements and collaborate with you to create highly functional applications.`,
      ],
    },
    {
      para: [
        `No. We believe in following a systematic design process, and then, based on the client's project requirements, we provide custom Angular designs for functional and scalable websites.`,
      ],
    },
    {
      para: [
        `We offer a holistic range of services at our Angular Development Agency to create robust websites. We aim to build responsive and functional websites while integrating APIs and following industrial practices to deliver high-quality solutions.`,
      ],
      list: [
        `<span><h4 class="inline">Custom Angular Systems:</h4></span> - We create design systems in Angular for developers to utilize and efficiently develop apps or websites when needed..`,
        `<span><h4 class="inline">Angular API Integration:</h4></span> - We integrate angular UI with APIs and offer a complete front-end development solution.`,
        `<span><h4 class="inline">Custom Angular Components:</h4></span> - We create custom components that increase usability from the foundation of the Angular design system.`,
      ],
      para2: [
        `We also offer customised services related to <a href="/ui-development/" target="_blank" rel="noreferrer">UI Development</a>, <a href="/reactjs-development/" target="_blank" rel="noreferrer">ReactJs 
          Development</a>, etc.`,
      ],
    },
    {
      para: [
        `Our Angular Development Services help your business create visually appealing, intuitive, and user-friendly websites based on user preferences.`,
        `As an Angular Development Agency, we use Angular's component-based architecture and data binding capabilities to build websites for all types of devices, ensuring a smooth multi-platform user experience.`,
        `We focus on creating user-centric designs, incorporating best industrial practices and usability methods for enhanced customer experience.`,
      ],
    },
    {
      para: [
        `The duration and cost of our Angular Development Services vary depending on factors such as the project's complexity, desired features, tools and technologies used, and the size of the application.`,
        `At our Angular Development Agency, we follow a transparent approach. Please <a href="/contact-us/">reach out</a> for an accurate timeline and detailed price quotation so that we can guide you based on your business requirements.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
           {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Can your AngularJS Company integrate Angular with other design workflows?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes! As an Angular Application Development Agency, we have the expertise and experience to integrate Angular with other design workflows and create an interactive, highly functional, visually attractive, and intuitive website."
              }
            },{
              "@type": "Question",
              "name": "Does your AngularJS Development Agency offer dedicated Angular Developers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, we do offer dedicated Angular Developers who are experts in creating dynamic web solutions for different industries
          Our dedicated AngularJS Developers fulfill your project requirements and collaborate with you to create highly functional applications."
              }
            },{
              "@type": "Question",
              "name": "Do you offer templates made in Angular?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "No. We believe in following a systematic design process, and then, based on the client's project requirements, we provide custom Angular designs for functional and scalable websites."
              }
            },{
              "@type": "Question",
              "name": "What are your services under Angular.js Development Company?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We offer a holistic range of services at our Angular Development Agency to create robust websites. We aim to build responsive and functional websites while integrating APIs and following industrial practices to deliver high-quality solutions
          Custom Angular Systems—We create design systems in Angular for developers to utilize and efficiently develop apps or websites when needed.
          Angular API Integration—We integrate angular UI with APIs and offer a complete front-end development solution.
          Custom Angular Components—We create custom components that increase usability from the foundation of the Angular design system.
          
          We also offer customized services related to UI Development, ReactJs Development, etc."
              }
            },{
              "@type": "Question",
              "name": "How does your Angular Development Company enhance the user experience?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our Angular Development Services help your business create visually appealing, intuitive, and user-friendly websites based on user preferences.
          As an Angular Development Agency, we use Angular's component-based architecture and data binding capabilities to build websites for all types of devices, ensuring a smooth multi-platform user experience.
          We focus on creating user-centric designs, incorporating best industrial practices and usability methods for enhanced customer experience."
              }
            },{
              "@type": "Question",
              "name": "What is the duration and pricing of your Angular Development Services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The duration and cost of our Angular Development Services vary depending on factors such as the project's complexity, desired features, tools and technologies used, and the size of the application.
          At our Angular Development Agency, we follow a transparent approach. Please contact us for an accurate timeline and detailed price quotation so that we can guide you based on your business requirements."
              }
            }]
          }                                        
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode="grey" data={cta} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta2} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode="grey" data={cta3} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
                Our Successful Delivered <span className="h1-span">Angular Projects</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials title={"Read our Client’s Words"} />
          <Cta data={cta4} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta5} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="AngularJS Development Company | Octet Design Studio"
    description="Our AngularJS development company offers the best Angular solutions in India to build custom UI and convert them into AngularJs for optimal performance."
  />
);
