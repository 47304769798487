import React from 'react';

const Plus = ({size, fill}) => (
  <svg width={size ? size : "14"} height={size ? size : "14"} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 14V0L6 0L6 14H8Z" fill={fill ? fill : "white"} />
    <path d="M14 6H0V8H14V6Z" fill={fill ? fill : "white"} />
  </svg>
);

export default Plus;
