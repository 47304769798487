import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { Link } from "gatsby";
import loadingGif from "../../videos/loading-submit.gif";

const Cta = ({ data, mode, input, lgtextClass, gap, container }) => {
  const createMarkup = (content) => {
    return { __html: content };
  };

  // FORM
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const form = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_13oh6y7",
        "template_z3bt9nf",
        form.current,
        "X49fzMHS7L3Pb95EL"
      )
      .then((res) => {
        setSubscribed(true);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    e.target.reset();
  };
  return (
    <section
      className={`lg:py-[120px] md:py-[100px] py-[80px] lg:px-[100px] md:px-[40px] px-[20px] ${
        mode === "light"
          ? "bg-[#fff]"
          : mode === "grey"
          ? "bg-[#FAFBFB]"
          : container ? 'bg-[#fff] text-white !p-0' : "bg-[#121212] text-white"
      }`}
    >
      <div className={`container ${container ? 'bg-[#121212] md:py-[50px] py-[30px] md:px-[50px] px-[20px] container-py lg:max-w-[750px]' : ''}`}>
        <div
          className={`flex items-center ${
            input || gap
              ? container ? 'gap-[30px] flex-wrap lg:flex-nowrap' : "gap-[60px] flex-wrap lg:flex-nowrap"
              : "lg:gap-[90px] gap-[60px] flex-wrap lg:flex-nowrap"
          } w-full justify-between`}
        >
          {
            container ? 
            <h2 className="lg:text-[36px] md:text-[32px] md:max-w-[70%] lg:leading-[1.3] text-[26px] leading-[1.4] !font-medium font-heading" dangerouslySetInnerHTML={createMarkup(data.title)} /> :
            <h2
              className={`font-medium font-heading ${
                lgtextClass || "lg:text-[60px]"
              } md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[874px] max-w-full capitalize`}
              dangerouslySetInnerHTML={createMarkup(data.title)}
            ></h2>
          }
          {!input ? (
            <Link
              to={data.contactLink ? data.contactLink : "/contact-us/"}
              className={`${container ? 'py-[8px] px-[30px] text-[14px] leading-[22px]' : 'py-[15px] px-[50px] text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px]'} bg-[#53D4B5] hover:bg-[#43C5A5] hover:transition-all hover:duration-300 transition-all duration-300 font-medium tracking-[.02em] md:tracking-[.03em] text-[#2A2A2A] rounded-[3px]`}
            >
              {data.contactText ? data.contactText : "Contact Us"}
            </Link>
          ) : (
            <form
              onSubmit={handleSubmit}
              ref={form}
              className="lg:min-w-[486px] lg:w-auto w-full"
            >
              <input
                placeholder="Your Email"
                type="email"
                id="email"
                name="email"
                className="mb-[30px] md:text-[16px] text-[15px] w-full bg-transparent pb-[20px] contact-input text-white lg:text-[18px] font-[400] md:tracking-[.03em] tracking-[.02em] leading-[26px] md:leading-[30px]"
              />
              <button
                type="submit"
                className={`lg:py-[15px] flex items-center md:py-[12px] py-[8px] px-[50px] ${
                  subscribed
                    ? "bg-[#E9EEED] hover:bg-[#E9EEED] cursor-not-allowed"
                    : "bg-[#53D4B5] hover:bg-[#43C5A5]"
                } hover:transition-all hover:duration-300 transition-all duration-300 font-medium text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] text-[#2A2A2A] rounded-[3px]`}
              >
                {subscribed
                  ? "Subscribed"
                  : loading
                  ? "Subscribing"
                  : "Subscribe"}
                {loading ? (
                  <img
                    className="loading-gif"
                    src={loadingGif}
                    alt="Subscribing"
                  />
                ) : (
                  ""
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default Cta;
