import * as React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const CommonServiceComponent = ({ data, mode, condition, list, nopara, hire }) => {
  const imageData = useStaticQuery(graphql`
    query {
      dynamicImages: allFile(
        filter: {
          relativePath: {
            regex: "/^(who-needs|why-work-with|why-opt-for|how-we-conduct|vet-it|what-are-benefits|what-service-image-angular|what-service-image)/"
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const imagesByPath = {};
  imageData.dynamicImages.edges.forEach(({ node }) => {
    const imagePath = node.relativePath;
    const imageData = getImage(node.childImageSharp);
    imagesByPath[imagePath] = imageData;
  });
  const imagePaths = data.img?.map((image) => {
    const imageNode = imageData.dynamicImages.edges.find(
      ({ node }) => node.relativePath === image
    );
    if (imageNode) {
      return imageNode.node.relativePath;
    }
    return null;
  });
  const createMarkup = (content) => {
    return { __html: content };
  };
  return (
    <section
      className={`lg:py-[200px] md:py-[120px] py-[80px] w-full overflow-hidden relative z-[1] ${
        mode === "light" ? "bg-[#FFF]" : mode === 'grey' ? "bg-[#FAFBFB]" : "bg-[#121212] text-white"
      }
      ${hire && 
        `bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top`
      }`
    }
    >
      <div className="container">
        <h2
          className={`${nopara  ? 'lg:mb-[60px] mb-[30px]' : 'lg:mb-[30px] mb-[20px]'} font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[880px] capitalize`}
          dangerouslySetInnerHTML={createMarkup(data.title)}
        ></h2>
        <p
          className="text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] text-with-link max-w-[800px]"
          dangerouslySetInnerHTML={createMarkup(data.linkText)}
        ></p>
        <p
          className={`${
            data.para2 ? "mb-[40px]" : "lg:mb-[50px] md:mb-[40px] mb-[30px]"
          } link-text-para text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] max-w-[800px]`}
          dangerouslySetInnerHTML={createMarkup(data.para)}
        ></p>
        {data.para2 && (
          <p className="text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] max-w-[800px] mb-[50px]">
            {data.para2}
          </p>
        )}
        {imagePaths?.map((imagePath, index) => {
          return (
            <div
              className="w-[100%] lg:mb-[50px] md:mb-[40px] mb-[30px]"
              key={index}
            >
              <GatsbyImage
                image={imagesByPath[imagePath]}
                title={data.imageTitle ? data.imageTitle : ""}
                alt={data.imageAlt ? data.imageAlt : "service"}
                key={index}
              />
            </div>
          );
        })}
        <div className="flex lg:gap-[50px] md:gap-[40px] gap-[30px] flex-wrap">
          {data.content.map((item, index) => (
            <div className={`${list ? 'lg:max-w-[800px]' : 'lg:w-[calc(50%-25px)]'} w-full`} key={index}>
              <h3 className={`${hire ? 'font-semibold' : 'font-medium'} mb-[10px] leading-[27px] font-heading lg:leading-[55px] text-[20px] md:text-[28px] lg:text-[35px] md:leading-[40px]`}>
                <span dangerouslySetInnerHTML={createMarkup(item.title)}></span>
                {condition ? (
                  <span className="font-body">
                    <small>*</small>
                  </span>
                ) : (
                  ""
                )}
              </h3>
              <p
                className="text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] lg:leading-[30px] tracking-[.02em] md:tracking-[.53px]"
                dangerouslySetInnerHTML={createMarkup(item.text)}
              ></p>
            </div>
          ))}
        </div>
        {data.desc ? (
          <p className="mt-[50px] text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] lg:leading-[30px] tracking-[.02em] md:tracking-[.03em]">
            {data.desc}
          </p>
        ) : (
          ""
        )}
      </div>
      {mode === "light" || hire ? (
        ""
      ) : (
        <StaticImage
          placeholder="none"
          className="!absolute right-0 bottom-0 z-[-1]"
          src="../../images/guides-service-common-2.webp"
          alt="guides"
        />
      )}
    </section>
  );
};

export default CommonServiceComponent;
