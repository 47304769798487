import React, { useState } from "react";
import Plus from "../images/plus";
import Minus from "../images/minus";
const Faq = ({ Questions, section, des, faqData, grey, title }) => {
  const [activeIndexes, setActiveIndexes] = useState([]);
  const handleClick = (index) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((i) => i !== index));
    } else {
      setActiveIndexes([...activeIndexes, index]);
    }
  };
  const createMarkup = (content) => {
    return { __html: content };
  };
  return (
    <section
      className={`${!section
          ? "lg:pt-[200px] md:pt-[120px] pt-[80px]"
          : "lg:py-[200px] md:py-[120px] py-[80px]"
      } ${grey ? 'bg-[#F4F6F6]' : ''}`}
    >
      <div className="container">
      {title ? 
        <h2 className="lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]" dangerouslySetInnerHTML={{ __html: title }} /> :
        <h2 className="lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
          Frequently <br className="hidden lg:block" /> Asked Questions
        </h2>
      }
        <div className="md:mb-[60px] mb-[40px] ">
          <p
            className="md:tracking-[.03em] tracking-[.02em] faq-des md:text-[16px] md:leading-[1.8750] text-[15px] link-text-para lg:text-[18px] lg:leading-[1.67] leading-[1.733] lg:max-w-[800px] max-w-[688px]"
            dangerouslySetInnerHTML={createMarkup(des)}
          ></p>
        </div>
        {Questions.map((question, index) => (
          <div
            className={`pr-[23px] md:pr-[38px] lg:pr-[0] faq-component border-b border-[#E9EEED] ${
              activeIndexes.includes(index)
                ? "open lg:pb-[40px] md:pb-[25px] pb-[15px]"
                : ""
            }`}
            key={index}
          >
            <div
              role="button"
              tabIndex="0"
              className={`flex lg:gap-[25px] md:pl-[20px] transition-all duration-[300ms] hover:duration-[300ms] md:gap-[20px] ${
                activeIndexes.includes(index) ? "" : "hover:bg-[#FAFBFB]"
              }  gap-[15px] lg:pt-[50px] md:pt-[45px] pt-[32px] ${"pb-[15px] lg:pb-[40px] md:pb-[25px]"}`}
              onClick={() => handleClick(index)}
              onKeyDown={() => handleClick(index)}
            >
              <button
                aria-label="Toggle FAQ"
                className="lg:w-[36px] lg:min-w-[36px] lg:h-[36px] w-[28px] min-w-[28px] h-[28px] flex items-center justify-center rounded-[50px] bg-[#53D4B5] transition-all duration-[300ms] hover:duration-[300ms]"
              >
                {activeIndexes.includes(index) ? <Minus /> : <Plus />}
              </button>
              <h3 className="lg:text-[30px] normal md:text-[22px] text-[16px] leading-[1.33] font-heading font-medium">
                {question}
              </h3>
            </div>
            <div
              className={`max-w-[851px] lg:pl-[61px] md:pl-[48px] pl-[43px] overflow-hidden faq-content ${
                activeIndexes.includes(index) ? "open" : ""
              } `}
            >
              {faqData[index]?.para?.map((text, index) => (
                <p
                  key={index}
                  className="link-text-para lg:text-[18px] md:text-[16px] text-[15px] md:tracking-[.03em] tracking-[.02em] leading-[1.875] lg:leading-[1.67] lg:mb-[25px] mb-[20px]"
                  dangerouslySetInnerHTML={createMarkup(text)}
                ></p>
              ))}
              {faqData && (
                <ul>
                  {faqData[index]?.list?.map((item, index) => (
                    <li
                      key={index}
                      className="pl-[13px] link-text-para faq-item lg:text-[18px] md:text-[16px] text-[15px] md:tracking-[.03em] tracking-[.02em] leading-[1.875] lg:leading-[1.67] lg:mb-[25px] mb-[20px]"
                      dangerouslySetInnerHTML={createMarkup(item)}
                    ></li>
                  ))}
                </ul>
              )}
              {faqData[index]?.para2?.map((text, index) => (
                <p
                  key={index}
                  className="link-text-para lg:text-[18px] md:text-[16px] text-[15px] md:tracking-[.03em] tracking-[.02em] leading-[1.875] lg:leading-[1.67] lg:mb-[25px] mb-[20px]"
                  dangerouslySetInnerHTML={createMarkup(text)}
                ></p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Faq;
